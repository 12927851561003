<template>
  <div>
    <v-card-text>
      <div v-if="items.length == 0">
        <v-alert v-show="show" text type="info"
          >No hay data para mostrar</v-alert
        >
      </div>
      <div v-else @click.right.prevent @copy.prevent @paste.prevent>
        <v-card outlined class="white pa-2">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">PRODUCTO</th>
                  <th class="text-left">GRUPO</th>
                  <th class="text-left">ESTADO</th>
                  <th class="text-left">TIPO VALOR</th>
                  <th class="text-left">VALOR</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td>{{ item.Producto }}</td>
                  <td>{{ item.Grupo }}</td>
                  <td>{{ item.Estado }}</td>
                  <td>{{ item.TipoValor }}</td>
                  <td>{{ item.Valor }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-autocomplete
          :items="tipoProducto"
          :filter="customFilter"
          :loading="progress"
          v-model="tipoproductoSelected"
          item-text="ProductoName"
          item-value="ProductoTipoId"
          placeholder="Tipos de productos"
          required
          append-icon="keyboard_arrow_down"
          :rules="[(v) => !!v || 'Requerido']"
        ></v-autocomplete>
        <v-autocomplete
          :items="listProductoCodes"
          :filter="customFilter"
          :loading="progress"
          v-model="productCodesSelected"
          item-text="ProductName"
          item-value="ProductID"
          placeholder="Price plan"
          required
          multiple
          append-icon="keyboard_arrow_down"
          :rules="[(v) => !!v || 'Seleccionar Price Plan']"
        ></v-autocomplete>
        <br />
        <div class="mb-5 text-right">
          <v-btn block class="ma-1" depressed color="primary" @click="get">
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>
<script>
import apiEsquema from "@/api/esquemas";
import apiProducto from "@/api/productos";

export default {
  data() {
    return {
      drawerRight: true,
      url: "",
      selected: null,
      show: true,
      progress: false,
      items: [],
      tipoproductoSelected: "",
      productCodesSelected: "",
      listProductoCodes: [],
      customFilter(item, queryText) {
        const hasValue = (val) => (val != null ? val : "");
        const text = hasValue(item.ProductoName) || hasValue(item.ProductCode);
        const query = hasValue(queryText);
        return (
          text
            .toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
        );
      },
    };
  },
  computed: {
    tipoProducto() {
      return this.$store.getters.tipoProducto;
    },
    currentUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
    get() {
      this.items = [];
      this.progress = true;
      let param = {
        productos: this.productCodesSelected,
        asociadosComisionGroup: this.currentUser.seleccion.empleados.map(
          (c) => c.PuestoId
        ),
      };
      apiEsquema.getCalculadoraComisionEmpleado(param).then((response) => {
        this.items = response.data;
        this.progress = false;
      });
    },
    refreshProductCodes() {
      apiProducto
        .GetProductCodesByTipoID(this.tipoproductoSelected)
        .then((response) => {
          this.listProductoCodes = response.data;
        })
        .catch((error) => {
          this.setError(error);
        });
    },
  },
  watch: {
    tipoproductoSelected: function () {
      this.refreshProductCodes();
    },
  },
  mounted() {},
};
</script>